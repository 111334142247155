<template lang="pug">
  .main.d-flex.justify-content-center.flex-column.my-5
    img(src='https://cdn2.wuwow.tw/pictures/upgrade-test/check.svg' alt='')
    .main-text
      | 點擊”觀看挑戰說明”，
      br
      | 我們將為您解說考前須知，
      br
      | 請務必詳細閱讀，以免損失您的權益。
    div
      b-button(variant='primary' :to="{ name: 'InnerPage', params: { startTest: true }}") {{$t('view_explain')}}
    div
      b-button.btn(variant='primary' @click='toBack') {{$t('return')}}
</template>

<script type="text/javascript">
import { ViewExplainLang } from './lang.js';

export default {

  name: 'ViewExplain',

  components: {
  },

  i18n: ViewExplainLang,

  data() {
    return {
    };
  },

  computed: {

  },

  watch: {},

  created() {
  },

  methods: {
    toBack(){
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
@import './viewExplain';
</style>
